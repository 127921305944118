// Import your custom variables
@import './custom-variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900&family=Rubik&display=swap');

:root,
[data-bs-theme="light"] {
  --bs-body-bg: #fff;
  --bs-dark: #1A1C1E;
  --bs-white: #FFFFFF;
  --bs-primary: #DDC140;
  --bs-light: #E5E5E5;
  --bs-btn-font-size: 10px;
  --bs-highlight-color: #E5E5E5;
  --bs-secondary: #646468;
  --bs-secondary-text: #4A5564;
  --bs-black: #111;
  --bs-border-radius-pill: 15px;
  --bs-border-radius: 10px;
  --bs-border-color: #D5D5D7;
  --bs-text-black-color: #1E2938;
  --bs-scrollbar-size: 6px;
  --bs-scrollbar-color: #ebebeb;
  --bs-scrollbar-hover-color: #DBDFE9;
  --bs-font-family: 'Rubik', sans-serif;
  --bs-roboto-family: 'Roboto Flex', sans-serif;
  --bs-body-line-height: 1.5;

  // custom
  --gray--50: #B6B6B9;
  --gray--100: #B6B6B9;
  --gray--200: #97979B;
  --gray--300: #78787D;
  --gray--400: #646468;
  --gray--500: #505053;
  --gray--600: #3C3C3E;
  --gray--700: #28282A;
  --gray--800: #1E1E1F;
  --gray--900: #141415;

  --primary--100: #F9F4DC;
  --primary--200: #F6EECB;
  --primary--300: #F0E3A8;
  --primary--400: #EAD786;
  --primary--500: #E3CC63;
  --primary--600: #DDC140;
  --primary--700: #D0B125;
  --primary--800: #9C841C;
  --primary--900: #574A0F;


}

html {
  font-size: 1rem;
}

html,
body {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--bs-font-family);
}

body {

  line-height: var(--bs-body-line-height);
  font-weight: 400;
  overflow-x: hidden;
  background-color: #fff;
  font-family: var(--bs-font-family);
  font-size: 0.875rem;
}

.ic {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.ic-m {
  height: 24px;
  width: 24px;
  fill: currentColor;
}
.ic-notification{
  height: 17px;
  width: 14px;
  min-width: 14px;
}
svg {
  display: inline;
  color: inherit;
}

svg.ic {
  fill: currentColor;
}

// spacing
.mx-10 {
  margin-inline: 10px;
}

//font size
.font-14 {
  font-size: 14px;
  line-height: normal;
}

.font-16 {
  font-size: 16px;
  line-height: normal;
}

.font-20 {
  font-size: 20px;
  line-height: normal;
}

.font-24 {
  font-size: 24px;
  line-height: normal;
}

.font-36 {
  font-size: 36px;
  line-height: 48px;
}

// font size end
/*font family start*/
.font-regular,
.fw-400 {
  font-weight: 400;
}

.font-bold,
.fw-700,
.fw-bold {
  font-weight: 700;
}

.font-semibold,
.fw-600 {
  font-weight: 600;
}

.fw-500,
.font-medium {
  font-weight: 500;
}

/*font family end*/
//color css start
.text-gray-100 {
  color: var(--gray--100);
}

.text-gray-200 {
  color: var(--gray--200);
}

.text-gray-300 {
  color: var(--gray--300);
}

.text-gray-400 {
  color: var(--gray--400);
}

.text-gray-500 {
  color: var(--gray--500);
}

.text-gray-600 {
  color: var(--gray--600);
}

.text-gray-700 {
  color: var(--gray--700);
}

.text-gray-800 {
  color: var(--gray--800);
}

.text-primary-100 {
  color: var(--primary--100);
}

.text-primary-200 {
  color: var(--primary--200);
}

.text-primary-300 {
  color: var(--primary--300);
}

.text-primary-400 {
  color: var(--primary--400);
}

.text-primary-500 {
  color: var(--primary--500);
}

.text-primary-600 {
  color: var(--primary--600);
}

.text-primary-700 {
  color: var(--primary--700);
}

.text-primary-800 {
  color: var(--primary--800);
}

//color css end
.dropdown-toggle::after {
  display: none;
}

.sidebar {
  position: fixed;
  left: 0;
  height: calc(100dvh);
  top: 0;
  z-index: 3;
  bottom: 0;
  border-radius: 0;
  transition: transform .3s;
  -webkit-transition: transform .3s;
  -moz-transition: transform .3s;
  -o-transition: transform .3s;
  border-right: 1px solid var(--bs-border-color);
  width: 264px;
  min-width: 264px;
  @include media-breakpoint-up(lg) {
    min-width: 264px;
  }
}

.brand-logo {
  padding: 32px 24px;
}
main{
padding-top:  calc(64px + 24px);
padding-left: calc(264px + 48px);
padding-right: 48px;
}
// topbar css
.topbar {
  height: 64px;

  @include media-breakpoint-up(lg) {
    margin-left: 264px;
  }

}
.user-drop{
  .btn{
    background-color: transparent;
    border: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    &:focus{
      background-color: transparent;
    }
  }
}
// navbar
.navbar-toggler {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: 16px;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  position: relative
}

.navbar-toggler,
.navbar-toggler span.icon-bar {
  transition: all .2s;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  width: 18px
}

.navbar-toggler span.icon-bar {
  display: block;
  height: 2px;
  margin-left: auto;
  opacity: .8;
  text-align: right;
  background-color: #111;
}

.navbar-toggler span.icon-bar.bottom-bar {
  margin-bottom: 0
}

.navbar-toggler.cross .icon-bar {
  transition: all .2s;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s
}

.navbar-toggler.cross .top-bar {
  position: absolute;
  top: .5rem;
  transform: rotate(45deg)
}

.navbar-toggler.cross .middle-bar {
  opacity: 0
}

.navbar-toggler.cross .bottom-bar {
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  width: 18px !important
}

.menu {
  /* margin-top: 30px;
  padding: 20px 0; */
  position: relative;
  height: calc(100% - 30px);

  ul {
    li {
    
        margin-top: 24px;
      a {
        display: flex;
        padding: 8px 24px 8px 12px;
        border-radius: 8px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        color: var(--gray--800);
        font-family: var(--bs-roboto-family);
        font-size: 16px;
        border: 1px solid var(--bs-border-color);
        text-decoration: none;
      }
      &.active{
      a{
      border-color: var(--primary--700);
      background-color: var(--primary--100);

      }
      }
    }
  }
}

.overlay {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #262f37;
  opacity: 0.5;
  z-index: 0;
  display: none;
}

.overlay.show {
  display: block;
}

@media only screen and (max-width: 991px) {


  .sidebar.navHide {
    transition: transform .3s;
    -webkit-transition: transform .3s;
    -moz-transition: transform .3s;
    -o-transition: transform .3s;
    transform: translateX(calc(-100% - 5px));
    -webkit-transform: translateX(calc(-100% - 5px));
    -moz-transform: translateX(calc(-100% - 5px));
  }

  .menu ul li a {
    justify-content: start
  }

  .menu ul li {
    display: block;
  }

  .menu ul li a .nav-text {
    display: block;
    position: static;
    background: transparent;
  }

  .nav-text::before {
    content: none;
  }

}